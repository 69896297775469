import React, { useState, useEffect } from 'react';
import '../index.css';
import '../App.css';
import ClipLoader from 'react-spinners/ClipLoader';
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollToTop from "../components/scrolltotop"
import Footer from "../components/footer"
import Video from "../components/images/video/modelloultimo.mkv"
import { Intro } from "../components/Intro.tsx";
import { Controller, Scene } from "react-scrollmagic"
import { Link } from "react-router-dom"
import { HashLink as Link2 } from 'react-router-hash-link';
import Marquee from "react-fast-marquee";
import { Canvas } from "@react-three/fiber"
import { Center, OrbitControls } from "@react-three/drei";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Team from "../components/team"
import { useTranslation } from "react-i18next";

import ServicesBoxs from "../components/services-boxs"
import Contact2 from "../components/Contact2";
import Works from "./works";

const getVideoSrc = width => {
  if (width >= 720) return Video;
  if (width <= 720) return null;
  
};

const Videosrc = props => {
  const src = getVideoSrc(window.innerWidth);
  return (
    <div className="overflow-hidden" >
            <div className="row justify-content-end d-none d-sm-flex ">
              <div id="videointro" className="  col-12 col-lg-10">
                <div id="videoposition" >
                  <video poster='../components/images/scrivaniamobile.webp' autoPlay={true} loop={true} playsInline={true} muted={true}  >
                    <source src={src} type='video/mp4' />
                  </video>



                </div>

              </div>
            </div>
          </div>
  );
};


function App() {
  const [loadingInProgress, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    
    <div id="#triggerintro" className="">
      {loadingInProgress ? (
        <div className="loader-container">
         <ClipLoader color={'#fff'} loading={loadingInProgress} size={150} />
        </div>
      ) : (
      <div>
      <div className="w-100 overflow-hidden">
        <div id="fotoposition" className=" d-sm-none" >

        </div>
      </div>
      

      <div className="overflow-hidden" style={{ paddingTop: "5%" }}>
        <Controller>
          
         <Videosrc />
          <div className="overflow-hidden ">


          
            <div id="Home" className="row justify-content-start  " >
              <div className="">
                <Scene duration={0} classToggle="intro-revealed" reverse={false} triggerElement="#"  >
                  <div className="intro-unrevealed col-12 ">
                    <Intro />
                  </div>
                </Scene>

              </div>
              <div id="WeAre" className="overflow-hidden  col-md-8 col-lg-6 mt-5 mt-sm-0">
                <div className="row justify-content-start">
                  <div className="col-10 col-md-8 ">
                    
                    <p className=" "  >
                      {t("We are a web development Team")}. Nel 2020 abbiamo deciso di dare inizio alla realizzazioe di quello che oggi è il progetto WebSailors.
                      Perseveranza,personalità e professionalità sono le tre "P" che rappresentano i punti salienti del nostri servizi.
                    </p>
                    <Link2 to="/#services" className="col-10  col-lg-5 col-xxl-4   ">
                      <button className="button-2 col-6  ">
                        {t("Our services")}
                      </button>
                    </Link2>
                  </div>

                </div>


              </div>

            </div>
          </div>





        </Controller>
      </div>

<div id="services">
<Marquee  speed={60} gradient={false} style={{ marginTop: "15%" }}>


        <Link to="/#services">
          <h4 className="text-white">
            <span className="mx-4 ourcolor">Siti vetrina</span>
            <span className="mx-4">E-commerce</span>
            <span className="mx-4 ourcolor">Ottimizzazione mobile</span>
            <span className="mx-4">Spot pubblicitari</span>
            <span className="mx-4 ourcolor">Branding</span>
            <span className="mx-4">PhotoEditing</span>
            <span className="mx-4 ourcolor">Seo</span>
            <span className="mx-4">Design</span>
            <span className="mx-4 ourcolor">VideoEditing</span>
            <span className="mx-4 ">Meta advertising</span>
            <span className="mx-4 ourcolor">Web3.0</span>
            <span className="mx-4 ">Nft</span>
            <span className="mx-4 ourcolor">3d modelling</span>
            <span className="mx-4">PhotoEditing</span>
            <span className="mx-4 ourcolor">Design</span>
            <span className="mx-4 ">VideoEditing</span>
            <span className="mx-4 ourcolor">Web3.0</span>
            <span className="mx-4 ">Nft</span>
          </h4>
        </Link>


      </Marquee>
</div>
      
      <div id="triggerbox" className="overflow-hidden " >
      <div id="triggerhead" className="row justify-content-center  row">
        <Controller>
        <Scene duration={0} classToggle="h-head-scrolled"   triggerElement="#" reverse={false} >
          {(progress, event) => (
          <div  className="h-head col-11  " style={{textAlign:"center"}}>
          <h1 className="font-big-story text-uppercase my-5 py-2" >{t("Our Skills")} </h1>
          </div>
          )}
        </Scene>
        </Controller>
        </div>
        <div className="row justify-content-center">
          <div id="triggerhead" className="col-12 px-4 col-lg-8">

            <div className="">
              
              <div id="cardNonEsitate" className="col-12   ">
                <p style={{textAlign:"center"}}>

                {t("Il nostro obbiettivo è dare vita ad ogni vostra idea ed esigenza")}. {t("Per questo il nostro team ha tutte le competenze del caso per gestire ogni progetto a 360")}.
                  {t("Obviously")}, {t("for any request do not hesitate to contact us through the appropriate")}
                  <Link style={{ color: "#63fdd8" }} to="/Contact"><span className="ourcolor" >{t("contacts")}</span></Link>, {t("we will look for a solution for you and your ideas")}</p>

              </div>
            </div>
          </div>



          <div  className="col-12 col-lg-7  " style={{ paddingTop: "3%" }} >
            <Controller>
              <Scene duration={0} classToggle="services-revealed" reverse={false} triggerElement="#triggerbox" >

                <div className="services-unrevealed">
                  <ServicesBoxs />
                  
                </div>
              </Scene>
            </Controller>
          </div>
        </div>

        



      </div>
      
      <div  className="overflow-hidden ">
        <Works />
      </div>
      <div id="team" className="overflow-hidden">
        <Team />
        
      </div>

 
      <div id="contact" className="overflow-hidden">
        <Contact2 />
      </div>





      <div className="container" style={{ paddingTop: "5%" }}>

        <Footer />
      </div>

      





      <ScrollToTop />
      <CookieConsent
  location="bottom"
  buttonText="Accetta"
  buttonClasses="button-cookie"
  cookieName="myAwesomeCookieName2"
 disableButtonStyles

  buttonStyle={{padding:"10%", marginRight:"60px"}}
  style={{ background: "rgba(22, 22, 22, 0.8)", padding:"5%" }}
 
 
  expires={150}
>
  <div className="row">
    <div className="col-9">
    <h5>
Questo sito utilizza cookie per migliorare l'esperienza dell'utente.
  Per ulteriori informazioni consulta <Link className="ourcolor"to='/cookiepolicy'>Cookie Policy</Link> e <Link className="ourcolor"to='/privacypolicy'>Privacy Policy</Link>
</h5>
    </div>
  

  </div>
  

</CookieConsent>
      </div>
      
      )}
    </div>



  );


}
export default App;