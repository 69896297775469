import Carousel from 'react-bootstrap/Carousel';
import image1 from "./images/web2/10.jpg";
import image2 from "./images/web/layers.jpg";
import image3 from "./images/web2/13.jpg";
function Slider() {
  return (
    <div>
    <Carousel >
      <Carousel.Item  >
        <img 
          className="d-block w-100"
          src={image1}
          alt="First slide"
        />
        <Carousel.Caption>
       
          {/*<p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>*/}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image2}
          alt="Second slide"
        />

        <Carousel.Caption>
          
          {/*<p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>*/}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image3}
          alt="Third slide"
        />

        <Carousel.Caption >
          
          {/*<p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>*/}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default Slider;