import React, { useState } from 'react';
import "./components.css"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom"
import { HashLink as Link2 } from 'react-router-hash-link';
import { ReactComponent as GitHubsvg } from "./images/icons/github-icon.svg";
import { ReactComponent as Instasvg } from "./images/icons/instagram-icon.svg";
import { ReactComponent as Twittersvg } from "./images/icons/twitter-icon.svg";
import { ReactComponent as TikToksvg } from "./images/icons/tiktok-icon.svg";
import { ReactComponent as Logosvg } from "./images/icons/logo.svg";
import { Controller, Scene } from 'react-scrollmagic';
import { useTranslation } from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import i18next from 'i18next';

const languages = [
  {
    code: 'it',
    name: 'Italiano',
    country_code: 'it'
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb'
  },
  {
    code: 'sp',
    name: 'Spanish',
    country_code: 'sp'
  },
]
const GlobeIcon = ({width = 24, height = 24}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="#63fdd8" className="bi bi-globe2" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887
   1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 
   3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 
   0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124
    9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344
     12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 
     5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176
      1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282
       3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01
        7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63
         12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488
          1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
</svg>
)
function Navbar() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (

    <div id="navbar" className="" bg="none" variant="dark "
      expand="sm">
      <div id="triggernav" >
        <Controller>
          <ul id="ulNav" >
            <Link2 to="/">
            <Logosvg className="d-block d-lg-none col-6 logosm" />
            </Link2>
            
            
            <Scene duration={0} classToggle="" triggerElement="#triggerbox"  >
            <Link className='d-none d-lg-block    ' to="/">
              <Logosvg className="logolg"/>
              </Link>
              </Scene>
              
              
            <Scene duration={0} classToggle="nav-revealed" triggerElement="#triggernav"  ><li className='liNav'><Link2 className='d-none d-lg-block nav-unrevealed p-0' to="/#Home">{t("Home")}</Link2></li></Scene>
            
            <Scene duration={0} classToggle="nav-revealed3" triggerElement="#triggernav"  ><li className='liNav' ><Link2 className='d-none d-lg-block nav-unrevealed p-0' to="/#services">{t("Services")}</Link2></li></Scene>
            <Scene duration={0} classToggle="nav-revealed4" triggerElement="#triggernav"  ><li className='liNav'><Link2 className="d-none d-lg-block nav-unrevealed p-0" to="/#works" >{t("Works")}</Link2></li></Scene>
            
            <Scene duration={0} classToggle="nav-revealed5" triggerElement="#triggernav"  ><li className='liNav'><Link2 className='d-none d-lg-block nav-unrevealed p-0' to="/#contact">{t("Contact")}</Link2></li></Scene>
            <Scene duration={0} classToggle="nav-revealed5" triggerElement="#triggernav"  ><li id="liNavAnalisi" className='liNav'><Link2 className='  d-none d-lg-block nav-unrevealed p-0' to="/#contact"><button id="analisi" className=''>Richiedi analisi sito gratuita</button></Link2></li></Scene>
            <Scene duration={0} classToggle="nav-revealed5" triggerElement="#triggernav"  >
              <li className='liNav p-0'>
              <div className="dropdown d-none d-lg-block mt-0">
                <button 
                
                className="btn  dropdown-toggle" 
                type="button" 
                data-bs-toggle="dropdown" 
                aria-expanded="false">
                  <GlobeIcon />
                </button>
                <ul className="dropdown-menu">
                 
                    <li key="en">
                    <button
                    onClick={() => i18next.changeLanguage("en")}
                     className="dropdown-item text-black" href="#">
                      <span className={'fi fi-gb mx-2'}></span>
                    English
                    </button>
                    </li> 
                    <li key="it">
                    <button 
                    onClick={() => i18next.changeLanguage("it")}
                    className="dropdown-item text-black" href="#">
                      <span className={'fi fi-it mx-2'}></span>
                    Italiano
                    </button>
                    </li> 
                     
                  
                 

                </ul>
              </div>
              </li>
              
              
              

            </Scene>
               <li>
                
               </li>
            <button className='d-block d-lg-none button-1  w-25 m-3 ' variant="primary" onClick={handleShow}>
              {t("Discover")}
            </button>
            <div className="dropdown d-block d-lg-none mt-4">
                <button 
                
                className="btn  dropdown-toggle" 
                type="button" 
                data-bs-toggle="dropdown" 
                aria-expanded="false">
                  <GlobeIcon />
                </button>
                <ul className="dropdown-menu">
                 
                    <li key="en">
                    <button
                    onClick={() => i18next.changeLanguage("en")}
                     className="dropdown-item text-black" href="#">
                      <span className={'fi fi-gb mx-2'}></span>
                    English
                    </button>
                    </li> 
                    <li key="it">
                    <button 
                    onClick={() => i18next.changeLanguage("it")}
                    className="dropdown-item text-black" href="#">
                      <span className={'fi fi-it mx-2'}></span>
                    Italiano
                    </button>
                    </li> 
                     
                  
                 

                </ul>
              </div>


          </ul>
        </Controller>
      </div>


      <Offcanvas scroll={true} show={show} onHide={handleClose} placement="end" style={{ width: "60vw" }} className="offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Web Sailors</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          <div className="row justify-content-center">
            <div className="col-9">
              <Link2 className='link-b d-block m-2 text-black' to="/" onClick={handleClose}>{t("Home")}</Link2>
              
              <Link2 className='link-b d-block m-2 text-black' to="/#services" onClick={handleClose}>{t("Services")}</Link2>
              <Link2 className='link-b d-block m-2 text-black' to="/#works" onClick={handleClose}>{t("Works")}</Link2>
              <Link2 className='link-b d-block m-2 text-black' to="/#contact" onClick={handleClose}>{t("Contact")}</Link2>
              
            </div>
            <Link2 id="analisimobile"className=' d-block m-2 text-black button-2 ' to="/#contact" onClick={handleClose}>Richiedi analisi  gratuita</Link2>
          </div>
          <h5 className="text-uppercase mt-5">{t("Follow us on social")}:</h5>
          <div className="container ">
            <div className="offsetlogos d-flex justify-content-center">
              <a className="m-1" target="_blank" href="https://github.com/WebSailors" ><GitHubsvg /></a>
              <a className="m-1"  target="_blank" href="https://instagram.com/w3bsailors?igshid=YmMyMTA2M2Y=0"><Instasvg /></a>
              <a className="m-1" target="_blank" href="https://twitter.com/sailors_web"><Twittersvg /></a>
              <a className="m-1" target="_blank" href="https://www.tiktok.com/@websailors"><TikToksvg /></a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Navbar;