
import Heidi from "../components/images/avatar/heidi2.webp"
import Daniele from "../components/images/avatar/daniele.png"
import Giorgia from "../components/images/avatar/giorgia2.webp"
import Alessandro from "../components/images/avatar/alessandro2.webp"
import Aleks from "../components/images/avatar/aleks2.webp"

import { Link} from "react-router-dom"
import { useTranslation } from "react-i18next";

function Team(){
  const { t } = useTranslation();
    return(
      
<div className="overflow-hidden " style={{marginTop:"8%"}}>

<div className="row justify-content-center "  >
  <h1 id="ourTeam" className="col-12 text-uppercase mb-4" >{t("The Team")}</h1>
  
<div className="card col-5 col-md-3 col-lg-2 m-1  "  >
<Link to="/services/FullStack"><div  className="row justify-content-center">
  <img id="alessandro" src={Alessandro} >
</img>
<div style={{textAlign:"center"}}>
<h4 className="text-white" >Alessandro Malaguti</h4>
<h6 className="text-white">Web Developer</h6>
<small>View more</small>
</div>

</div>
</Link>


</div>
<div  className="card col-5 col-md-3 col-lg-2 m-1 ">
<Link to="/services/Social">
<div  className="row justify-content-center">
  <img id="heidi" src={Heidi} >
</img>
<div style={{textAlign:"center"}} className="mt-1">
<h4 className="text-white" >Heidi Maria Valleriani</h4>
<h6 className="text-white">SocialMedia Manager</h6>
<small>View more</small>
</div>
</div>
</Link>

</div>
<div className="card col-5 col-md-3 col-lg-2 m-1">
<Link to="services/Design">
<div  className="row justify-content-center">
<img id="giorgia" src={Giorgia} >
</img>
<div style={{textAlign:"center"}}>
<h4 className="text-white" >Giorgia Manieri</h4>
<h6 className="text-white">Designer</h6>
<small>View more</small>
</div>
</div>
</Link>
</div>
<div className="card col-5 col-md-3 col-lg-2 m-1">
<Link to="services/Design">
<div  className="row justify-content-center">
<img id="dani"src={Daniele} >
</img>
<div className="row justify-content-center" style={{textAlign:"center"}}>
<h4 className="text-white col-11 col-md-12" >Daniele Lotti</h4>
<h6 className="text-white col-12">Designer</h6>
<small>View more</small>
</div>
</div>
</Link>
</div>

</div>


</div>
    )
}
export default Team;