import React from "react"
import "../components/components.css"

import { ReactComponent as Logosvg } from "./images/icons/logo.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link} from "react-router-dom"

const Footer2 = () => <footer className="row  justify-content-between row-cols-1  pt-5 my-1 border-top">
    






<div className="col">

<p  style={{textAlign:"center"}}className="text-muted ">p.iva: 16994201008 <br></br>©Websailors 2022 </p>
</div>





</footer>

export default Footer2;