import Footer from "../components/footer"
import ScrollToTop from "../components/scrolltotop"
import {Controller,Scene} from "react-scrollmagic";
import {Link} from "react-router-dom";
import "./works.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Macaron from "../components/images/Works/macaron2.webp"
import Architettura from "../components/images/Works/architettura2.webp"
import Toc from "../components/images/Works/toc.webp"
import { useTranslation } from "react-i18next";
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Works(){
    const { t } = useTranslation();
    return(
      <div className="overflow-hidden ">
        <div id="triggerhead " className="row justify-content-center mt-5 mb-3">
        <Controller>
        <Scene duration={0} classToggle="h-head-scrolled"   triggerElement="#" reverse={false} >
          {(progress, event) => (
          <div className="h-head  col-11 ">
          <h1 className="font-big-story text-uppercase"  style={{textAlign:"center"}}>Realizzazioni </h1>
          </div>
          )}
        </Scene>
        </Controller>
        </div>
        <div className="row justify-content-center  " >
        <Row xs={1} md={2} lg={3} className="g-4  col-9 col-md-11 mt-0">
        <Col>
        
      <Card className="m-2 workscard">
        <a  target="_blank" href="http://showcase2.websailorsdev.com" rel="noreferrer">
          <Card.Img className="worksimg"variant="top" alt="Sito vetrina sviluppato con wordpress" src={Architettura}  />
        <Card.Body>
          <Card.Title className="text-white">De Angelis Architettura</Card.Title>
          <Card.Text>
            Sito sviluppato per uno studio di architettura. Minimal elegante e intuitivo, veloce ed ottimizzato per uso mobile. Facile da modificare 
          </Card.Text>
        </Card.Body>
        <Card.Footer className="footercard">
          <small className="text-muted">Scopri di più</small>
        </Card.Footer></a>
        
      </Card>
      </Col>
      <Col>
      <Card className="m-2 workscard">
      <a target="_blank" href="http://showcase.websailorsdev.com" rel="noreferrer">
        <Card.Img className="worksimg"variant="top" alt="Sito vetrina sviluppato con wordpress" src={Macaron}  />
        <Card.Body>
          <Card.Title className="text-white">Macarons MonAmour</Card.Title>
          <Card.Text>
            Sito web dedicato alla vendita di prodotti tramite ordinazione.
             Veloce ed ottimizzato per mobile.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="footercard">
          <small className="text-muted">Scopri di più</small>
        </Card.Footer>
        </a>
      </Card>
      </Col>
      <Col>
        
      <Card className="m-2 workscard">
        <a  target="_blank" href="https://www.tradingoncue.it" rel="noreferrer">
          <Card.Img className="worksimg"variant="top" alt="Sito vetrina sviluppato con wordpress" src={Toc}  />
        <Card.Body>
          <Card.Title className="text-white">Trading On Cue </Card.Title>
          <Card.Text>
            Sito web dedicato alla vendita di percorsi formativi in ambito trading. Prodotto perfetto per una massima conversione del cliente.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="footercard ">
          <small className="text-muted">Scopri di più</small>
        </Card.Footer></a>
        
      </Card>
      </Col>
      
 
      
      
    
    </Row>
       

      
    
    </div>
      </div>

    );
}
export default Works;